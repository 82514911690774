<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <div @click="returnToPreviousPage" style="cursor: pointer;">
            <icon icon="#cx-hea1-arrow-left" />
          </div>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.services.item-details") }}
        </div>
        <template v-slot:right>
          <button
            @click="
              this.$router.push({
                name: this.backLinkName,
                params: this.$route.params,
              })
            "
            v-if="canSave.length == 0"
          >
            <icon icon="#cx-hea1-save" /></button
        ></template>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section" v-if="resourceDetails">
      <li class="clebex-item-section-item full-right-underline">
        <div
          class="clebex-item-content-wrapper"
          @click="showInfoBox = !showInfoBox"
          style="cursor: pointer;"
        >
          <div
            class="clebex-section-input"
            style="cursor: pointer;"
            @click="showInfoBox = !showInfoBox"
          >
            <label
              class="clebex-section-input-label"
              for="orderNumber"
              style="cursor: pointer;"
              @click="showInfoBox = !showInfoBox"
              >{{ displayLabelName("export.services.service-item") }}</label
            >
            <span
              style="cursor: pointer; color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
              @click="showInfoBox = !showInfoBox"
            >
              {{ serviceItem?.data?.name }}</span
            >
          </div>
          <span class="follow-up-icon-item" style="cursor: pointer;">
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              style="cursor: pointer; margin-right: 15px; margin-top: 5px;"
              :style="[
                !showInfoBox
                  ? 'transform: rotate(90deg)'
                  : 'transform: rotate(-90deg)',
              ]"
            />
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("resources.resource-function.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ infobox.resource }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.order-number")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{
                serviceOrder && serviceOrder.data
                  ? serviceOrder.data.order_id
                  : infobox.order_id
              }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="resource">{{
              displayLabelName("services.services.resource")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ infobox.path }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="address">{{
              displayLabelName("services.services.address")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ infobox.address }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="requestBy">{{
              displayLabelName("services.services.request-by")
            }}</label>
            <Field
              name="name"
              as="input"
              type="text"
              id="requestBy"
              disabled="true"
              v-model="infobox.request_by"
            />
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="datetime">{{
              displayLabelName("services.services.created-at")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{
                serviceOrder && serviceOrder.data
                  ? serviceOrder.data.created_at
                  : infobox.created_at
              }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="datetime">{{
              displayLabelName("services.services.event-starts")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ displayDate(selectedDateFromParsed) }}
              {{ selectedHourFrom }}</span
            >
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-if="showInfoBox"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="datetime">{{
              displayLabelName("services.services.event-ends")
            }}</label>
            <span
              style="color: #999999; width: 100%; padding-left: 15px; padding-right: 15px; line-height: 1.3; font-size: 16px;"
            >
              {{ displayDate(selectedDateToParsed) }} {{ selectedHourTo }}</span
            >
          </div>
        </div>
      </li>
    </ul>
    <VeeForm
      :key="formKey"
      ref="addServiceOrderForm"
      @submit="submitForm"
      class="form inline-input edit-form"
      novalidate
      style="height: auto;"
    >
      <ul class="clebex-item-section pill" style="margin-bottom: 0px;">
        <!-- Requested time -->

        <span class="delivery-period">{{
          displayLabelName("services.services.requested-time")
        }}</span>
        <br /><br />
        <li class="clebex-item-section-item time" style="cursor: pointer;">
          <div
            class="clebex-item-content-wrapper"
            @click="showDateTimePicker = !showDateTimePicker"
          >
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <div class="clebex-item-range-cta">
                  {{ displayTime(requestedHourFrom) }}
                </div>
              </li>
              <li class="clebex-item-range separator">
                <icon icon="#cx-app1-time" width="16" height="16" />
              </li>
              <li class="clebex-item-range to">
                <div class="clebex-item-range-cta">
                  {{ displayTime(requestedHourTo) }}
                </div>
              </li>
            </ul>
          </div>
        </li>
        <!-- DateTimePicker -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="showDateTimePicker"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
            >
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[requestedHourFrom, requestedHourTo]"
                  :rangeMarked="false"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>

      <!-- Quantity -->

      <ul
        class="clebex-item-section pill"
        style="margin-bottom: 0px;"
        v-if="serviceItem && serviceItem.data && serviceItem.data.has_quantity"
      >
        <span class="delivery-period">{{
          displayLabelName("services.services.quantity")
        }}</span>
        <br /><br />
        <li class="clebex-item-section-item">
          <button
            class="clebex-item-content-wrapper"
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div style="display: flex; flex-direction: row;">
              <span class="change-quantity" @click="decreaseQuantity">-</span>
              <Field
                name="quantity"
                as="input"
                type="number"
                id="quantity"
                v-model="quantity"
                class="order-field"
                style="flex: 1; text-align: center; width: 100%; padding-right: 1%; padding-left: 1%;"
              />
              <span class="change-quantity" @click="increaseQuantity">+</span>
            </div>
          </button>
        </li>
      </ul>

      <!-- Cost codes -->

      <span
        v-if="
          serviceItem && serviceItem.data && serviceItem.data.uses_cost_code
        "
        class="order-level"
        style="margin-top: 25px; margin-left: 15px; margin-bottom: 15px !important"
        >{{ displayLabelName("services.services.cost-codes") }}
        <span
          style="color: rgba(36, 183, 175, 0.8); font-weight: bold; font-size: 17px; margin-left: 4px;"
          v-if="
            serviceItem &&
              serviceItem.data &&
              serviceItem.data.cost_code_required
          "
        >
          *</span
        ></span
      >
      <ul
        v-if="
          serviceItem && serviceItem.data && serviceItem.data.uses_cost_code
        "
        class="clebex-item-section pill"
        style="margin-bottom: 0px; margin-top: 0px;"
      >
        <li class="clebex-item-section-item" v-if="costCode">
          <button
            class="clebex-item-content-wrapper"
            @click="selectCostCode($event, costCode)"
          >
            <div style="display: flex; flex-direction: column; width: 100%;">
              <span class="cost-code-label">{{
                displayLabelName("services.services.personal-cost-code")
              }}</span>
              <span class="label">
                <span class="highlight cost-code">{{ costCode }}</span>
              </span>
            </div>

            <span class="follow-up-icons">
              <span
                class="follow-up-icon-item"
                v-if="checkedCostCode(costCode)"
              >
                <icon icon="#cx-app1-checkmark"></icon>
              </span>
            </span>
          </button>
        </li>
        <li class="clebex-item-section-item" v-if="projectCostCode">
          <button
            class="clebex-item-content-wrapper"
            @click="selectCostCode($event, projectCostCode)"
          >
            <div style="display: flex; flex-direction: column; width: 100%;">
              <span class="cost-code-label">{{
                displayLabelName("services.services.project-cost-code")
              }}</span>
              <span class="label">
                <span class="highlight cost-code">{{ projectCostCode }}</span>
              </span>
            </div>

            <span class="follow-up-icons">
              <span
                class="follow-up-icon-item"
                v-if="checkedCostCode(projectCostCode)"
              >
                <icon icon="#cx-app1-checkmark"></icon>
              </span>
            </span>
          </button>
        </li>
        <li class="clebex-item-section-item">
          <button
            class="clebex-item-content-wrapper"
            style="overflow: hidden;"
            @click="selectCostCode($event, manualCostCode)"
          >
            <div style="display: flex; flex-direction: column; width: 100%;">
              <span class="cost-code-label">{{
                displayLabelName("services.services.custom-cost-code")
              }}</span>

              <Field
                name="manual_cost_code"
                as="input"
                type="text"
                id="manualCostCode"
                @input="selectManualCostCode"
                @focus="selectManualCostCode"
                v-model="manualCostCode"
                class="manual-cost-code"
              />
            </div>

            <span class="follow-up-icons">
              <span
                class="follow-up-icon-item"
                v-if="checkedCostCode(manualCostCode)"
              >
                <icon icon="#cx-app1-checkmark"></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>

      <!-- Note -->

      <ul class="clebex-item-section pill" style="margin-bottom: 0px;">
        <span class="delivery-period">{{
          displayLabelName("services.services.note")
        }}</span>
        <br /><br />
        <li class="clebex-item-section-item">
          <button
            class="clebex-item-content-wrapper"
            style="display: flex; align-items: center; justify-content: center;"
            @click="$event.preventDefault()"
          >
            <Field
              name="note"
              as="input"
              type="text"
              id="note"
              v-model="note"
              class="order-field"
            />
          </button>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <br />
    <!-- Error messages -->
    <span
      class="order-level"
      style="margin-top: 20px; margin-left: 15px; color: red;"
      v-for="cs in canSave"
      v-bind:key="cs"
      >{{ cs }}</span
    >

    <!-- Cost code modal -->
    <screen-modal
      class="confirm-modal"
      type="success"
      :hideTimer="true"
      :confirm-action="returnToPreviousPageConfirmed"
      :confirm-button-label="displayLabelName('global.buttons.yes')"
      :cancelButtonLabel="displayLabelName('global.buttons.no')"
      :show="showCostCodeModal"
      @close="showCostCodeModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.services.cost-code-is-missing") }}
      </h3>
      <p class="text">
        {{ displayLabelName("services.services.missing-required-fields-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <!-- Confirmation modal -->
    <screen-modal
      class="confirm-modal"
      type="success"
      :hideTimer="true"
      :confirm-action="closeConfirmationModal"
      :confirm-button-label="displayLabelName('global.buttons.yes')"
      :cancelButtonLabel="displayLabelName('global.buttons.no')"
      :show="showConfirmationModal"
      @close="returnToPreviousPageConfirmed"
    >
      <h3 class="modal-title">
        {{
          displayLabelName("services.services.would-you-like-to-save-the-data")
        }}
      </h3>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "ItemDetails",
  data() {
    return {
      showDateTimePicker: false,
      preselectedTab: "time-slot",
      pillTabs: ["time-slot"],
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedHourFrom: null,
      selectedHourTo: null,
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      requestedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      requestedDateTo: DateTime.now().toFormat("yyyy-dd-MM"),
      requestedHourFrom: null,
      requestedHourTo: null,
      showInfoBox: false,
      quantity: 1,
      note: "",
      canSave: [],
      showCostCodeModal: false,
      showConfirmationModal: false,
    };
  },
  computed: {
    ...mapState("resource", [
      "resource",
      "resourceAttributes",
      "resourceServices",
      "selectedResourceAttributes",
      "selectedResourceServices",
    ]),
    ...mapState("service", [
      "resourceDetails",
      "selectedOrderItems",
      "serviceItem",
      "serviceOrderFrom",
      "serviceOrderTo",
      "serviceOrder",
      "infobox",
    ]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("user", ["userData"]),
    itemName() {
      if (this.serviceOrder && this.serviceOrder.data) {
        return this.serviceOrder.data.name;
      } else if (this.resource && this.resource.data) {
        return this.resource.data.name;
      } else {
        return null;
      }
    },
    costCode() {
      if (this.$store._state?.data?.user?.userData?.data?.cost_code) {
        return this.$store._state.data.user.userData.data.cost_code;
      }
      return null;
    },
    projectCostCode() {
      if (this.$store._state?.data?.user?.userData?.data?.project_cost_code) {
        return this.$store._state.data.user.userData.data.project_cost_code;
      }
      return null;
    },
    routeParam() {
      return this.$route.params.order_item_id;
    },
  },
  created() {
    this.getServiceItem(this.$route.params.order_item_id);
    if (this.$route.params.order_resource_id) {
      this.getResourceDetails(this.$route.params.order_resource_id);
    } else if (this.serviceOrder?.resource_id) {
      this.getResourceDetails(this.serviceOrder.resource_id);
    }

    this.getDates();
    let item = this.selectedOrderItems.find(
      (item) => item.id == this.$route.params.order_item_id
    );
    if (item) {
      this.quantity = item.quantity;
      this.note = item.note;

      this.checkErrors(this.selectedOrderItems);

      if (
        localStorage.getItem("costCode") &&
        (item.cost_code == "" || item.cost_code == null)
      ) {
        item.cost_code = localStorage.getItem("costCode");
      }
    } else {
      this.returnToPreviousPage();
    }
  },
  watch: {
    routeParam() {
      this.getServiceItem(this.$route.params.order_item_id);
      if (this.$route.params.order_resource_id) {
        this.getResourceDetails(this.$route.params.order_resource_id);
      } else if (this.serviceOrder?.resource_id) {
        this.getResourceDetails(this.serviceOrder.resource_id);
      }
      this.getDates();

      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      this.quantity = item.quantity;
      this.note = item.note;
      this.checkErrors(this.selectedOrderItems);

      if (
        localStorage.getItem("costCode") &&
        (item.cost_code == "" || item.cost_code == null)
      ) {
        item.cost_code = localStorage.getItem("costCode");
      }
    },
    serviceOrder() {
      this.getResourceDetails(this.serviceOrder.resource_id);
    },
    quantity() {
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      item.quantity = this.quantity;
    },
    note() {
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      item.note = this.note;
    },
    requestedHourFrom() {
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      item.requested_time_from = `${DateTime.fromISO(
        this.requestedDateFrom
      ).toISODate()} ${DateTime.fromISO(this.requestedHourFrom).toFormat(
        "HH:mm"
      )}`;
    },
    requestedHourTo() {
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      item.requested_time_to = `${DateTime.fromISO(
        this.requestedDateTo
      ).toISODate()} ${DateTime.fromISO(this.requestedHourTo).toFormat(
        "HH:mm"
      )}`;
    },
    selectedOrderItems: {
      handler(newVal) {
        this.checkErrors(newVal);
        if (!newVal.find((el) => el.id == this.$route.params.order_item_id)) {
          this.$router.push({
            name: this.backLinkName,
            params: this.$route.params,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("service", [
      "getResourceDetails",
      "getServiceItem",
      "selectOrderItem",
      "setPage",
      "setTotalPages",
      "getServiceOrders",
    ]),
    checkErrors(array) {
      this.canSave = [];
      array.forEach((item) => {
        if (item.id == this.$route.params.order_item_id) {
          if (
            item.cost_code_required &&
            (item.cost_code == "" || item.cost_code == null)
          ) {
            this.canSave.push(
              this.displayLabelName("services.services.cost-code-is-missing")
            );
          }
        }
      });
    },
    getDates() {
      if (this.serviceOrderFrom && this.serviceOrderTo) {
        this.selectedDateFrom = DateTime.fromISO(
          this.serviceOrderFrom
        ).toFormat("yyyy-dd-MM");
        this.selectedDateTo = DateTime.fromISO(this.serviceOrderTo).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = DateTime.fromISO(
          this.serviceOrderFrom
        ).toFormat("yyyy-MM-dd");
        this.selectedDateToParsed = DateTime.fromISO(
          this.serviceOrderTo
        ).toFormat("yyyy-MM-dd");
        this.selectedHourFrom = DateTime.fromISO(
          this.serviceOrderFrom
        ).toFormat("HH:mm");
        this.selectedHourTo = DateTime.fromISO(this.serviceOrderTo).toFormat(
          "HH:mm"
        );

        this.requestedDateFrom = DateTime.fromISO(
          this.serviceOrderFrom
        ).toFormat("yyyy-MM-dd");
        this.requestedDateTo = DateTime.fromISO(this.serviceOrderTo).toFormat(
          "yyyy-MM-dd"
        );
        this.requestedHourFrom = DateTime.fromISO(
          this.serviceOrderFrom
        ).toFormat("HH:mm");
        this.requestedHourTo = DateTime.fromISO(this.serviceOrderTo).toFormat(
          "HH:mm"
        );
      }
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );

      let reqFrom = item.requested_time_from.replace(" ", "T");
      if (reqFrom.split("T")[1].length == 5) reqFrom += ":00";
      let reqTo = item.requested_time_to.replace(" ", "T");
      if (reqTo.split("T")[1].length == 5) reqTo += ":00";

      if (item && item.requested_time_from) {
        this.requestedDateFrom = DateTime.fromISO(reqFrom).toFormat(
          "yyyy-MM-dd"
        );
        this.requestedDateTo = DateTime.fromISO(reqTo).toFormat("yyyy-MM-dd");
        this.requestedHourFrom = DateTime.fromISO(reqFrom).toFormat("HH:mm");
        this.requestedHourTo = DateTime.fromISO(reqTo).toFormat("HH:mm");
      }
    },
    checkedCostCode(costCode) {
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      if (item?.cost_code == null) {
        return false;
      } else if (item?.cost_code == costCode) {
        return true;
      }
      return false;
    },
    selectCostCode(event, costCode) {
      event.preventDefault();
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      if (item.cost_code == costCode) {
        item.cost_code = null;
        localStorage.removeItem("costCode");
      } else {
        item.cost_code = costCode;
        localStorage.setItem("costCode", costCode);
      }
    },
    selectManualCostCode(event) {
      event.preventDefault();
      this.manualCostCode = event.target.value;
      let item = this.selectedOrderItems.find(
        (item) => item.id == this.$route.params.order_item_id
      );
      item.cost_code = this.manualCostCode;
      localStorage.setItem("costCode", this.manualCostCode);
    },
    increaseQuantity(event) {
      event.preventDefault();
      this.quantity++;
    },
    decreaseQuantity(event) {
      event.preventDefault();
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    returnToPreviousPage() {
      if (this.canSave.length > 0) {
        this.showCostCodeModal = true;
      } else {
        this.showConfirmationModal = true;
      }
    },
    returnToPreviousPageConfirmed() {
      this.selectOrderItem(
        this.selectedOrderItems.filter(
          (item) => item.id != this.$route.params.order_item_id
        )
      );
      this.showCostCodeModal = false;
      this.showConfirmationModal = false;
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params,
      });
    },
    closeConfirmationModal() {
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params,
      });
    },
    selectHour(hours) {
      this.requestedHourFrom = hours[0];
      this.requestedHourTo = hours[1];
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values && this.submitFormState) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      const dateTimeFrom = `${DateTime.fromISO(
        this.serviceOrderFrom
      ).toISODate()} ${DateTime.fromISO(this.selectedHourFrom).toFormat(
        "HH:mm"
      )}`;
      const dateTimeTo = `${DateTime.fromISO(
        this.serviceOrderTo
      ).toISODate()} ${DateTime.fromISO(this.selectedHourTo).toFormat(
        "HH:mm"
      )}`;

      this.submitFormState = false;
      values = {
        resource_id: this.infobox.resource_id,
        service_status_id: 1,
        service_items: this.formValues(),
        delivery_start: dateTimeFrom,
        delivery_end: dateTimeTo,
        declaration_id: this.$route.params.order_booking_id
          ? this.$route.params.order_booking_id
          : null,
      };

      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const url = String(this.$route.name).includes("add-order")
        ? apiEndpoints.company.serviceOrders
        : `${apiEndpoints.company.serviceOrders}/${this.$route.params.service_order_id}`;

      const method = String(this.$route.name).includes("add-order")
        ? "post"
        : "put";

      httpServiceAuth[method](url, values)
        .then((response) => {
          this.setPage(null);
          this.setTotalPages(null);
          this.getServiceOrders();
          this.$router.push({
            name: "r_services-edit-order",
            params: { service_order_id: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
    formValues() {
      let response = [];

      for (var i = 0; i < this.selectedOrderItems.length; i++) {
        let object = {
          quantity: null,
          note: "",
          requested_time_from: null,
          requested_time_to: null,
          cost_code: "",
          service_status: "ORDER",
        };
        object.id = this.selectedOrderItems[i].id;

        object.quantity = this.selectedOrderItems[i].quantity;
        object.note = this.selectedOrderItems[i].note;
        object.requested_time_from = this.selectedOrderItems[
          i
        ].requested_time_from;
        object.requested_time_to = this.selectedOrderItems[i].requested_time_to;
        object.cost_code = this.selectedOrderItems[i].cost_code;

        response.push(object);
      }
      return response;
    },
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    ),
  },
};
</script>
<style>
.order-wrapper {
  border: 1px solid lightgray !important;
  border-top: 0px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 3px;
}
.order-item {
  border-radius: 0px !important;
  background: transparent !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
.order-label {
  padding-left: 0px !important;
}
.order-field {
  background: white !important;
  padding: 5px !important;
  height: 2rem !important;
  outline: 0px;
  width: 99%;
  border: 0px !important;
  box-shadow: none;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
}
.service-item-info {
  background-color: white;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}
.delivery-period {
  color: #999999;
  font-size: 0.9375rem;
  line-height: 1.26667em;
  letter-spacing: -0.36px;
  margin-left: 1rem;
  margin-bottom: 5px !important;
}
.order-type-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  transform: rotate(-90deg);
  margin-right: 15px;
}
.order-level {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 0px !important;
  margin-left: 7px;
}
.service-order-type {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: 0.8rem;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.625rem;
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
  align-items: center;
}
.change-quantity {
  border: 1px solid lightgray;
  padding-top: 1px;
  font-size: 18px;
  cursor: pointer;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeff4;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.cost-code {
  color: #999999 !important;
  padding-left: 5px;
  font-size: 0.9375rem;
}

.cost-code-label {
  color: #373f4e;
  padding-top: 5px;
  padding-left: 8px;
  font-size: 0.9375rem;
  text-align: left;
}
.manual-cost-code,
.manual-cost-code:active,
.manual-cost-code:focus {
  outline: none;
  font-size: 15px;
  padding-left: 8px;
  height: 2rem;
  color: #999999;
  font-weight: 400;
  box-shadow: none;
  background-color: #fff !important;
  height: 2rem !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: color 5000s ease-in-out 0s, background-color 5000s ease-in-out 0s;
  background-color: #fff !important; /* Change to your desired color */
  color: #999999 !important; /* Ensure text color is also adjusted if necessary */
}
</style>
